import styles from "./WithdrawKizChecked.module.scss";
import { useLocation, useNavigate } from "react-router-dom";

export default function WithdrawKizChecked() {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const totalProducts = queryParams.get("totalProducts");
  const totalUniqueProducts = queryParams.get("totalUniqueProducts");
  const totalOwnProducts = queryParams.get("totalOwnProducts");
  return (
    <div>
      <h2 className={styles.title}>
        ✅ Статусы ваших КИЗов из отчета актуальны
      </h2>
      <div className={styles.description}>
        <ul>
          <li>Количество строк в отчёте: {totalProducts}</li>
          <li>Обработано КИЗов: {totalUniqueProducts}</li>
          <li>Принадлежит вашему ИНН: {totalOwnProducts}</li>
        </ul>
      </div>
      <div className={styles.description}>
        Что это значит:
        <ol>
          <li>
            Мы проверили каждый КИЗ из загруженного вами отчета в Честном Знаке;
          </li>
          <li>
            Сверили его действующий статус со статусом в отчёте (в ручном режиме
            такая проверка 100 КИЗов занимает ±1 час + высока вероятность
            ошибки);
          </li>
          <li>
            Убедились в том, что все ваши КИЗы из отчета сейчас в актуальном
            статусе - вам менять их не надо!
          </li>
        </ol>
        Поздравляем, это поистине большая редкость!🥳
      </div>
      <p className={styles.description}>
        <i>
          PS Мы рекомендуем регулярно делать сверку, чтобы поддерживать их в
          актуальном состоянии и избежать{" "}
          <a
            href="https://xn--80ajghhoc2aj1c8b.xn--p1ai/penalties/"
            target="_blank"
          >
            штрафов
          </a>
        </i>
      </p>
      <p className={styles.description}>
        Для проверки другого отчета вернитесь{" "}
        <a href="#" onClick={() => navigate(-1)}>
          по этой ссылке
        </a>
      </p>
    </div>
  );
}
