import styles from "./Header.module.scss";

export default function Header() {
  return (
    <div className={styles.header}>
      <div className={styles.logo}>
        <img className={styles.img} src="/logo40.png" alt="logo" />

        <div className={styles.title}>
          <div className={styles.titleTop}>Честный знак</div>
          <div className={styles.titleButtom}>легко</div>
        </div>
      </div>
    </div>
  );
}
