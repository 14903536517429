import { CircularProgress } from "@mui/material";
import styles from "./Loader.module.scss";

interface TProps {
  show: boolean;
  message: string;
}

export function Loader({ show, message }: TProps) {
  return (
    <>
      {show && (
        <div className={styles.loaderContainer}>
          <CircularProgress />
          <span className={styles.message}>{message}</span>
        </div>
      )}
    </>
  );
}
