import { Certificate, getUserCertificates } from "crypto-pro";
import { useEffect, useState } from "react";

export const useFetchUserCertificates = () => {
  const [certificates, setCertificates] = useState<Certificate[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const result = await getUserCertificates();

        setCertificates(result);
      } catch (error: unknown) {
        console.log(error);
      }
    })();
  }, []);

  return certificates;
};
