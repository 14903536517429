import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import styles from "./WithdrawFromCirculationSender.module.scss";
import { CustomIcon } from "../../customIcon";
import FlashCertificateSelector from "../../FlashCertificateSelector/FlashCertificateSelector";
import {
  actualizeKizStates,
  getAccessTokenInfo,
  prepareWithdrawFromCirculation,
} from "src/services/WithdrawFromCirculationApiService";
import { toast } from "react-toastify";
import axios from "axios";
import { MarketplaceType, ServerErrors } from "src/models/Models";
import { Certificate, createSignature } from "crypto-pro";
import { useParams, Link } from "react-router-dom";
import AccessTokenStatistics from "../../AccessTokenStatistics/AccessTokenStatistics";
import { createOrUpdateInfoToast } from "src/utils/toastExtensions";
import WithdrawReport from "../WithdrawReportUploader/WithdrawReportUploader";
import {
  ActualizeKizDocument,
  CirculationDocumentType,
} from "src/models/WithdrawFromCirculationModels";
import { Loader } from "src/components/Loader/Loader";

type RouteParams = {
  token: string;
};

export default function UpdSender() {
  const [trueApiToken, setTrueApiToken] = useState<string>();
  const [certificate, setCertificate] = useState<Certificate>();
  const [isParsing, setIsParsing] = useState<boolean>(false);
  const [isTokenValid, setIsTokenValid] = useState<boolean>(true);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [hasAnyRecords, setHasAnyRecords] = useState<boolean>(false);
  const [sentCount, setSentCount] = useState<number>(0);
  const [marketplaceType, setMarketplaceType] = useState<MarketplaceType>(
    MarketplaceType.Wildberries
  );
  const [sellerInn, setSellerInn] = useState<string>();
  const { token } = useParams<RouteParams>();

  useEffect(() => {
    (async () => {
      if (!token) {
        setIsTokenValid(false);
        return;
      }

      let info = await getAccessTokenInfo(token);
      setIsTokenValid(info.isValid);

      createOrUpdateInfoToast("info", <AccessTokenStatistics info={info} />, {
        position: "top-left",
        toastId: "info",
        closeButton: false,
        icon: false,
      });

      createOrUpdateInfoToast(
        "adv",
        <div>
          <b>Отправка УПД</b>:
          <br />
          ✅ Проверю КИЗы из АКТа;
          <br />
          ✅ Сформирую УПД;
          <br />
          ✅ Подпишу и отправлю УПД;
          <br />
          <br />
          Также в 3 клика🔥
          <br />
          Подробнее{" "}
          <Link target="_blank" to="https://t.me/eazycheezychannel/12">
            в телеграм канале
          </Link>
        </div>,
        {
          position: "top-left",
          toastId: "adv",
          closeButton: false,
          icon: false,
        }
      );
    })();
  }, [token, sentCount]);

  async function handleOnClick() {
    if (clickButtonDisabled()) return;

    try {
      setIsParsing(true);
      let startTime = Date.now();
      let prepareResult = await prepareWithdrawFromCirculation(
        trueApiToken!,
        uploadedFile!,
        token!,
        marketplaceType,
        sellerInn!
      );

      let timeTaken = Date.now() - startTime;
      const timeLeft = 3000 - timeTaken;
      if (timeLeft > 0) {
        await new Promise((resolve) => setTimeout(resolve, timeLeft));
      }
      if (prepareResult.documents.length === 0) {
        clearFileInputs();
        window.location.href = `/withdraw/kiz-checked?totalProducts=${prepareResult.totalProducts}&totalUniqueProducts=${prepareResult.totalUniqueProducts}&totalOwnProducts=${prepareResult.totalOwnProducts}`;
        return;
      }

      let signedDocuments: ActualizeKizDocument[] = await Promise.all(
        prepareResult.documents.map(async (x) => {
          let signature = await createSignature(
            certificate!.thumbprint,
            x.encryptedContent
          );

          return {
            encryptedContent: x.encryptedContent,
            signature: signature,
            type: x.type,
            productGroup: x.productGroup,
          };
        })
      );

      await actualizeKizStates(
        trueApiToken!,
        token!,
        signedDocuments,
        sellerInn!,
        marketplaceType,
        prepareResult.fulfillmentType
      );

      clearFileInputs();

      let withdrawn = prepareResult.documents
        .filter((x) => x.type === CirculationDocumentType.Withdraw)
        .map((x) => x.count)
        .reduce((acc, curr) => acc + curr, 0);

      let returned = prepareResult.documents
        .filter((x) => x.type === CirculationDocumentType.Return)
        .map((x) => x.count)
        .reduce((acc, curr) => acc + curr, 0);

      window.location.href = `/withdraw/success-send?totalProducts=${prepareResult.totalProducts}&totalUniqueProducts=${prepareResult.totalUniqueProducts}&totalOwnProducts=${prepareResult.totalOwnProducts}&withdrawn=${withdrawn}&returned=${returned}`;
    } catch (error) {
      if (axios.isAxiosError<ServerErrors>(error)) {
        let errorData: ServerErrors =
          error.response?.data instanceof Blob
            ? JSON.parse(await error.response?.data.text())
            : error.response?.data;
        toast.error(errorData.errors.join("; "));
      } else {
        console.error(error);
      }
    } finally {
      setIsParsing(false);
    }
  }

  const clearFileInputs = () => {
    setSentCount(sentCount + 1);
    setUploadedFile(undefined);
  };
  const handleMarketplaceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedMarketplace = event.target.value as MarketplaceType;
    setMarketplaceType(selectedMarketplace);
    setUploadedFile(undefined);
  };

  function clickButtonDisabled(): boolean {
    return !trueApiToken || !uploadedFile || isParsing || !hasAnyRecords;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Вывести из оборота в 3 шага</div>

      <div className={styles.actions}>
        {!isTokenValid && (
          <div className={styles.error}>
            Исчерпан лимит отправок или ссылка недействительна. Чтобы обработать
            отчёт запросите новую ссылку в{" "}
            <Link target="_blank" to={`${process.env.REACT_APP_TG_BOT_URL}`}>
              боте
            </Link>
          </div>
        )}

        <div>
          <div className={styles.actionCardHeader}>
            <CustomIcon name="key" className={styles.icon} />
            <div className={styles.stepLabel}>Выберите сертификат</div>
          </div>

          <FlashCertificateSelector
            onChange={(token, selectedCertificate, inn) => {
              setTrueApiToken(token);
              setCertificate(selectedCertificate);
              setSellerInn(inn);
            }}
            disabled={!isTokenValid}
            askInnFromStart={true}
          />
        </div>

        <div>
          <div className={styles.actionCardHeader}>
            <CustomIcon name="marketplace" className={styles.icon} />
            <div className={styles.stepLabel}>Выберите маркетплейс</div>
          </div>

          <div className={styles.marketplaces}>
            <label>
              <input
                type="radio"
                name="marketplaceType"
                value={MarketplaceType.Wildberries}
                onChange={handleMarketplaceChange}
                checked={marketplaceType === MarketplaceType.Wildberries}
                disabled={!isTokenValid}
              />
              <span className={styles.wildberries}>Wildberries</span>
            </label>
            <label>
              <input
                type="radio"
                name="marketplaceType"
                value={MarketplaceType.Ozon}
                onChange={handleMarketplaceChange}
                checked={marketplaceType === MarketplaceType.Ozon}
                disabled={!isTokenValid}
              />
              <span className={styles.ozon}>Ozon</span>
            </label>
          </div>
        </div>

        <div>
          <div className={styles.actionCardHeader}>
            <CustomIcon name="document" className={styles.icon} />
            <div className={styles.stepLabel}>Загрузите отчет</div>
          </div>

          <WithdrawReport
            key={`${sentCount}-${marketplaceType}`}
            onChange={(file, hasRecords) => {
              setUploadedFile(file);
              setHasAnyRecords(hasRecords);
            }}
            trueApiToken={trueApiToken}
            token={token!}
            sellerInn={sellerInn!}
            disabled={!isTokenValid || !certificate}
            marketplaceType={marketplaceType}
          />
        </div>

        <Button
          variant="contained"
          size="large"
          fullWidth
          onClick={handleOnClick}
          className={styles.sendButton}
          disabled={clickButtonDisabled()}
        >
          <div className={styles.sendButtonText}>Вывести/вернуть в оборот</div>
        </Button>

        <Loader show={isParsing} message="Обработка в процессе..." />
      </div>
    </div>
  );
}
