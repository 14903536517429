import { Route } from "react-router";
import { BrowserRouter, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import UpdSender from "./components/Upd/UpdSender/UpdSender";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotFound from "./components/NotFound/NotFound";
import UpdSuccessSend from "./components/Upd/UpdSuccessSend/UpdSuccessSend";
import UpdSuccessDownload from "./components/Upd/UpdSuccessDownload/UpdSuccessDownload";
import UpdLastSent from "./components/Upd/UpdLastSent/UpdLastSent";
import WithdrawFromCirculationSender from "./components/WithdrawFromCirculation/WithdrawFromCirculationSender/WithdrawFromCirculationSender";
import WithdrawLastSent from "./components/WithdrawFromCirculation/WithdrawLastSent/WithdrawLastSent";
import WithdrawSuccessSend from "./components/WithdrawFromCirculation/WithdrawSuccessSend/WithdrawSuccessSend";
import WithdrawKizChecked from "./components/WithdrawFromCirculation/WithdrawKizChecked/WithdrawKizChecked";
import { ConfirmContextProvider } from "./components/ConfirmDialog/useConfirm";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />

        <ToastContainer
          autoClose={0}
          closeOnClick={false}
          draggable={false}
          toastStyle={{ marginTop: "50px" }}
        />
        <ConfirmContextProvider>
          <div className="app">
            <div className="window">
              <div className="topLine"></div>
              <div className="content">
                <Routes>
                  <Route path="upd/success-send" element={<UpdSuccessSend />} />
                  <Route path="upd/last-upd-sent" element={<UpdLastSent />} />
                  <Route
                    path="upd/success-download"
                    element={<UpdSuccessDownload />}
                  />
                  <Route path="upd/:token?" element={<UpdSender />} />

                  <Route
                    path="withdraw/success-send"
                    element={<WithdrawSuccessSend />}
                  />

                  <Route
                    path="withdraw/kiz-checked"
                    element={<WithdrawKizChecked />}
                  />
                  <Route
                    path="withdraw/last-sent"
                    element={<WithdrawLastSent />}
                  />
                  <Route
                    path="withdraw/:token?"
                    element={<WithdrawFromCirculationSender />}
                  />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </div>
            </div>
          </div>
        </ConfirmContextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
