import { ChangeEvent, useState, MouseEvent } from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import styles from "./WbAcceptanceCertificate.module.scss";
import { ServerErrors } from "src/models/Models";
import { parseWbAct } from "src/services/UpdApiService";
import { toast } from "react-toastify";
import axios from "axios";
import { ParseUpdFileResponse } from "src/models/UpdModels";
import UpdDetails from "../UpdDetails/UpdDetails";

interface TProps {
  onChange: (
    file: File,
    isLegalEntity: boolean,
    isAddressMissing: boolean,
    needConfirm: boolean,
    hasCriticalErrors: boolean
  ) => void;
  token: string;
  disabled?: boolean;
  trueApiToken?: string;
}

export default function AcceptanceCertificate({
  onChange,
  disabled,
  token,
  trueApiToken,
}: TProps) {
  const [fileName, setFileName] = useState<string | null>(null);
  const [parsedDetails, setParsedDetails] =
    useState<ParseUpdFileResponse | null>(null);

  const [isParsing, setIsParsing] = useState(false);

  const handleFileOnChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (!file || !trueApiToken) return;

    setFileName(file.name);

    try {
      setIsParsing(true);

      let response = await parseWbAct(file, token, trueApiToken!);
      setParsedDetails(response);
      let needConfirm =
        response.totalUniqueMarkedProducts !== response.totalIntroduced;
      let hasCriticalErrors = response.totalReadyToIntroduce > 0;
      onChange(
        file,
        response.isLegalEntity,
        response.isAddressMissing,
        needConfirm,
        hasCriticalErrors
      );
    } catch (error) {
      if (axios.isAxiosError<ServerErrors>(error)) {
        toast.error(`${error.response?.data.errors.join("; ")}`);
      } else {
        console.error(error);
      }
      setParsedDetails(null);
    } finally {
      setIsParsing(false);
    }
  };

  const clearHtmlInput = async (event: MouseEvent<HTMLInputElement>) => {
    event.currentTarget.value = "";
  };

  return (
    <div>
      <Button
        component="label"
        variant="outlined"
        startIcon={<CloudUploadIcon />}
        fullWidth
        className={styles.fileButton}
        disabled={disabled}
      >
        {fileName?.substring(0, 35) || "Выберите файл"}
        <input
          className={styles.hiddenInput}
          type="file"
          onChange={handleFileOnChange}
          onClick={clearHtmlInput}
          accept=".xlsx"
        />
      </Button>

      <UpdDetails parsedDetails={parsedDetails} isParsing={isParsing} />
    </div>
  );
}
