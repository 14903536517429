import { ChangeEvent, useState, useRef } from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import styles from "./LamodaFile.module.scss";
import { TextField } from "@mui/material";
import { ServerErrors } from "src/models/Models";
import { toast } from "react-toastify";
import axios from "axios";
import { ParseUpdFileResponse } from "src/models/UpdModels";
import { parseLamodaAct } from "src/services/UpdApiService";
import UpdDetails from "../UpdDetails/UpdDetails";

interface TProps {
  onChange: (
    file: File,
    isLegalEntity: boolean,
    isAddressMissing: boolean,
    sellerInn: string,
    needConfirm: boolean,
    hasCriticalErrors: boolean
  ) => void;
  disabled?: boolean;
  token: string;
  trueApiToken?: string;
}

export default function LamodaFile({
  onChange,
  disabled,
  token,
  trueApiToken,
}: TProps) {
  const [fileName, setFileName] = useState<string | null>(null);
  const [parsedDetails, setParsedDetails] =
    useState<ParseUpdFileResponse | null>(null);

  const [isParsing, setIsParsing] = useState(false);
  const [sellerInn, setSellerInn] = useState<string | undefined>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileOnChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (!file || !sellerInn || !trueApiToken) return;

    setFileName(file.name);

    try {
      setIsParsing(true);

      let response = await parseLamodaAct(file, token, sellerInn, trueApiToken);
      setParsedDetails(response);
      let needConfirm =
        response.totalUniqueMarkedProducts !== response.totalIntroduced;
      let hasCriticalErrors = response.totalReadyToIntroduce > 0;

      onChange(
        file,
        response.isLegalEntity,
        response.isAddressMissing,
        sellerInn,
        needConfirm,
        hasCriticalErrors
      );
    } catch (error) {
      if (axios.isAxiosError<ServerErrors>(error)) {
        toast.error(`${error.response?.data.errors.join("; ")}`);
      } else {
        console.error(error);
      }
      setParsedDetails(null);
    } finally {
      setIsParsing(false);
    }
  };

  const clearHtmlInput = async () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      setFileName("");
    }
  };

  return (
    <div className={styles.container}>
      <TextField
        label="ИНН"
        size="small"
        className={styles.inn}
        disabled={disabled}
        onChange={(e) => {
          clearHtmlInput();
          return setSellerInn(e.target.value);
        }}
      />

      <Button
        component="label"
        variant="outlined"
        startIcon={<CloudUploadIcon />}
        fullWidth
        className={styles.fileButton}
        disabled={disabled || !sellerInn}
      >
        {fileName?.substring(0, 35) || "Выберите файл поставки"}
        <input
          ref={fileInputRef}
          className={styles.hiddenInput}
          type="file"
          onChange={handleFileOnChange}
          onClick={clearHtmlInput}
          accept=".xlsx"
        />
      </Button>

      <UpdDetails parsedDetails={parsedDetails} isParsing={isParsing} />
    </div>
  );
}
