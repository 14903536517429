export type AuthKeyResponse = {
  uuid: string;
  data: string;
};

export type ServerErrors = {
  errors: string[];
};

export enum MarketplaceType {
  Wildberries = "Wildberries",
  Ozon = "Ozon",
  DetMir = "DetMir",
  Lamoda = "Lamoda",
  SberMegaMarket = "SberMegaMarket",
}

export enum FulfillmentType {
  Fbo = "Fbo",
  Fbs = "Fbs",
}

export enum ActionType {
  SignAndSend = "SignAndSend",
  Download = "Download",
}

export type AccessTokenInfo = {
  isValid: boolean;
  usedCount: number;
  allowedUseCount?: number;
  usedInns: string[];
  allowedInnCount: number;
  isUnlimited: boolean;
  daysLeft?: number;
};
