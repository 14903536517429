import cn from "classnames";
import { CSSProperties, FC } from "react";

import styles from "./customIcon.module.scss";
import { TCustomIcons } from "./customIcon.types";

import { ReactComponent as KeyIcon } from "src/assets/svg/key.svg";
import { ReactComponent as DocumentIcon } from "src/assets/svg/document.svg";
import { ReactComponent as PersonIcon } from "src/assets/svg/person.svg";
import { ReactComponent as MarketplaceIcon } from "src/assets/svg/marketplace.svg";
import { ReactComponent as UpdActionIcon } from "src/assets/svg/updAction.svg";

export const iconsMap = {
  key: <KeyIcon />,
  document: <DocumentIcon />,
  person: <PersonIcon />,
  marketplace: <MarketplaceIcon />,
  updAction: <UpdActionIcon />,
};

type Props = {
  name: TCustomIcons;
  maxWidth?: number;
  className?: string;
  style?: CSSProperties & { [key: string]: string };
};

export const CustomIcon: FC<Props> = ({ name, maxWidth, className, style }) => {
  return (
    <div
      className={cn(styles.container, className)}
      style={{ ...style, maxWidth }}
    >
      {iconsMap[name]}
    </div>
  );
};
