import { Link } from "react-router-dom";
import styles from "./WithdrawLastSent.module.scss";

export default function WithdrawLastSent() {
  return (
    <div>
      <h2 className={styles.title}>✅ Статусы КИЗов успешно изменены</h2>
      <p className={styles.description}>
        Это была последнее действие в рамках вашего пакета.
      </p>
      <p className={styles.description}>
        Чтобы обработать новый отчёт запросите новую ссылку в{" "}
        <Link target="_blank" to={`${process.env.REACT_APP_TG_BOT_URL}`}>
          боте
        </Link>
      </p>
    </div>
  );
}
