import { Link } from "react-router-dom";
import styles from "./NotFound.module.scss";

export default function NotFound() {
  return (
    <div>
      <h2 className={styles.title}>Такой страницы не существует, увы</h2>
      <p className={styles.description}>
        Чтобы получить доступ перейдите по ссылке в{" "}
        <Link target="_blank" to={`${process.env.REACT_APP_TG_BOT_URL}`}>
          бота
        </Link>
      </p>
    </div>
  );
}
