import { isValidSystemSetup } from "crypto-pro";
import { useEffect } from "react";
import { toast } from "react-toastify";

export const useEnsureCryptoPluginsInstalled = () => {
  useEffect(() => {
    (async () => {
      let isCryptoPluginInstalled = false;
      try {
        isCryptoPluginInstalled = await isValidSystemSetup();
      } catch (error: unknown) {
        console.log(error);
        isCryptoPluginInstalled = false;
      }

      if (!isCryptoPluginInstalled) {
        toast.error(
          "Для работы с Честный Знак необходимо установить плагин КриптоПро CSP"
        );
      }
    })();
  }, []);
};
