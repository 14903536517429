import { useMemo, useState } from "react";
import Select, { SingleValue } from "react-select";
import { useFetchUserCertificates } from "src/hooks/useFetchUserCertificates";
import dayjs from "dayjs";
import { TextField } from "@mui/material";
import styles from "./FlashCertificateSelector.module.scss";
import { getAuthToken } from "src/services/AuthApiService";
import { toast } from "react-toastify";
import { Certificate } from "crypto-pro";
import { useEnsureCryptoPluginsInstalled } from "src/hooks/useEnsureCryptoPluginsInstalled";
import { autoCloseOptions } from "src/utils/toastExtensions";
import { Loader } from "../Loader/Loader";

interface TProps {
  onChange: (token: string, certificate: Certificate, inn: string) => void;
  disabled?: boolean;
  askInnFromStart?: boolean;
}

export default function FlashCertificateSelector({
  onChange,
  disabled,
  askInnFromStart,
}: TProps) {
  useEnsureCryptoPluginsInstalled();
  const certificates = useFetchUserCertificates();
  const [isParsing, setParsingState] = useState(false);
  const [certificate, setCertificate] = useState<Certificate>();
  const [inn, setInn] = useState<string>();
  const [isInnRequired, setIsInnRequired] = useState(false);
  const [isInnCorrect, setIsInnCorrect] = useState(true);

  const mappedCerts = useMemo(
    () =>
      certificates.map((i) => ({
        label: `${i.name} до ${dayjs(i.validTo).format("DD.MM.YYYY")}`,
        value: i.thumbprint,
      })),
    [certificates]
  );

  async function selectCertificate(
    option: SingleValue<{ label: string; value: string }>
  ) {
    setIsInnRequired(false);
    setParsingState(true);
    const selectedCertificate = certificates.find(
      ({ thumbprint }) => thumbprint === option?.value
    );

    if (!selectedCertificate) return;

    try {
      onChangeInn("");
      setCertificate(selectedCertificate);
      let token = await getAuthToken(selectedCertificate);
      toast.success(
        "Сертификат и шифрование успешно настроены для работы с Честный Знак",
        autoCloseOptions
      );

      let owner = await selectedCertificate.getOwnerInfo();
      let inn =
        owner.filter((x) => x.title === "ИНН ЮЛ").length === 1
          ? owner.filter((x) => x.title === "ИНН ЮЛ")[0].description
          : owner.filter((x) => x.title === "ИНН")[0].description;

      onChange(token, selectedCertificate, inn);
    } catch (error) {
      console.error(error);
      toast.warn(
        "Похоже вы используете флешку физ.лица. Введите ИНН огранизации в кабинет которой хотите попасть"
      );
      setIsInnRequired(true);
    } finally {
      setParsingState(false);
    }
  }

  async function selectCertificateWithInn(inn: string) {
    setParsingState(true);

    if (!certificate || !inn || !isInnValid(inn)) {
      setParsingState(false);
      return;
    }

    try {
      let token = await getAuthToken(certificate, inn);
      toast.success(
        "Сертификат и шифрование успешно настроены для работы с Честный Знак",
        autoCloseOptions
      );
      onChange(token, certificate, inn);
    } catch (error) {
      console.error(error);
      toast.error(
        "Сертификат невалиден. Убедитесь что ИНН верный, флешка вставлена, не просрочена и на компьютер установлены необходимые программы"
      );
    } finally {
      setParsingState(false);
    }
  }

  function onChangeInn(inn: string) {
    setInn(inn);
    setIsInnCorrect(isInnValid(inn));
  }

  function isInnValid(inn: string) {
    let length = inn.length;
    let isNumber = /^\d+$/.test(inn);
    return (
      inn == null ||
      length === 0 ||
      ((length === 10 || length === 12) && isNumber)
    );
  }

  return (
    <div>
      <Select
        options={mappedCerts}
        placeholder="Не выбран"
        onChange={selectCertificate}
        isDisabled={disabled}
        className={styles.select}
      />
      {(isInnRequired || askInnFromStart) && (
        <TextField
          label="ИНН организации если флешка физ.лица"
          size="small"
          fullWidth={true}
          error={!isInnCorrect}
          helperText={!isInnCorrect ? "ИНН должен быть 10 или 12 цифр" : null}
          className={styles.inn}
          disabled={disabled}
          onBlur={(e) => selectCertificateWithInn(e.target.value)}
          onChange={(e) => onChangeInn(e.target.value)}
          value={inn}
        />
      )}

      <Loader show={isParsing} message="Проверяем корректность сертификата" />
    </div>
  );
}
