import { Link } from "react-router-dom";
import styles from "./UpdSuccessDownload.module.scss";

export default function UpdSuccessDownload() {
  return (
    <div>
      <h2 className={styles.title}>✅ УПД успешно отправлен в телеграм</h2>
      <p className={styles.description}>
        Теперь вы можете его отправить через ЭДО
      </p>
      <p className={styles.description}>
        Для отправки следующего УПД запросите новую ссылку в{" "}
        <Link target="_blank" to={`${process.env.REACT_APP_TG_BOT_URL}`}>
          боте
        </Link>
      </p>
    </div>
  );
}
