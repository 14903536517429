import axios from "axios";
import { AuthKeyResponse } from "../models/Models";
import { Certificate, createAttachedSignature } from "crypto-pro";

const baseUrl = process.env.REACT_APP_API_URL;

const getAuthKey = async (): Promise<AuthKeyResponse> => {
  const response = await axios.get("true-api/auth/key", { baseURL: baseUrl });
  return response.data;
};

const authenticate = async (
  uuid: string,
  signedData: string,
  inn?: string
): Promise<string> => {
  const response = await axios.post(
    "true-api/auth/token",
    { uuid: uuid, data: signedData, inn: inn },
    { baseURL: baseUrl }
  );
  return response.data;
};

export const getAuthToken = async (
  certificate: Certificate,
  inn?: string
): Promise<string> => {
  const auth = await getAuthKey();

  let signedData = await createAttachedSignature(
    certificate.thumbprint,
    auth.data
  );
  signedData = signedData.replace(/[\r\n\t]/g, "");

  const token = await authenticate(auth.uuid, signedData, inn);

  return token;
};
