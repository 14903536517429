import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ReactNode } from "react";
import { useConfirm } from "./useConfirm";
import styles from "./ConfirmDialog.module.scss";
import classNames from "classnames";

interface TProps {
  title?: string;
  content?: ReactNode;
  yesButton?: string;
  yesButtonLink?: string;
  noButton?: string;
}

export default function ConfirmDialog({
  title,
  content,
  yesButton,
  yesButtonLink,
  noButton,
}: TProps) {
  const { isAsking, message, deny, confirm } = useConfirm();
  return (
    <Dialog open={isAsking} onClose={deny}>
      <DialogTitle className={styles.dialogTitle} align="center">
        {title}
      </DialogTitle>

      <DialogContent className={styles.dialogContent}>
        {content ?? message}
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        {noButton!! && (
          <Button
            variant="contained"
            size="large"
            onClick={deny}
            className={classNames(styles.dialogButton, styles.noButton)}
          >
            {noButton}
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            if (yesButtonLink) {
              window.location.href = yesButtonLink;
              deny();
            }
            confirm();
          }}
          className={styles.dialogButton}
        >
          {yesButton ?? "Да"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
