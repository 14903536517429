import { toast, ToastOptions } from "react-toastify";

function createOrUpdateInfoToast(
  id: string,
  content: JSX.Element,
  options?: ToastOptions
) {
  const existingToast = toast.isActive(id);

  if (existingToast) {
    toast.update(id, {
      render: content,
      ...options,
    });
  } else {
    toast.info(content, {
      toastId: id,
      ...options,
    });
  }
}

const autoCloseOptions = { autoClose: 10000 };
export { createOrUpdateInfoToast, autoCloseOptions };
